export function getTripStatusColor(flag) {
  let colorsMap = {
    R: 'blue',
    A: 'red',
    U: 'yellow',
    O: 'purple',
    V: 'orange',
    C: 'green',
    T: 'red',
  }

  return colorsMap[flag] || 'fallback'
}

export function getTripStatusText(flag) {
  const aliases = {
    R: 'Reserved',
    A: 'Cancelled',
    U: 'Starting Trip',
    O: 'On Trip',
    V: 'Forced Cancel',
    C: 'Completed',
    T: 'Time Out',
  }

  return aliases[flag] || '--'
}
